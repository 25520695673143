<div class="grid-container">
    <h1 class="mat-h1">Dashboard</h1>
    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile *ngFor="let entryGroup of entryGroups" [colspan]="entryGroup.getCols" [rowspan]="entryGroup.getRows">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        {{entryGroup.getTitle}}
                        <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item>Expand</button>
                            <button mat-menu-item>Remove</button>
                        </mat-menu>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <mat-list>
                        <mat-list-item *ngFor="let val of entryGroup.getEntries | keyvalue">
                            {{val.value.identifier}}
                            <button mat-raised-button color="{{val.value.value == '1' ? 'primary':''}}" (click)="update(val.value)">{{val.value.value == '1' ? 'Allumé' : 'Éteint'}}</button>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
