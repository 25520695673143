import {Entry} from "./entry";

export class EntryGroup {

    private groupTitle: string;
    private entries: Map<string, Entry>;
    private cols: number;
    private rows: number;

    constructor() {
        this.entries = new Map<string, Entry>();
        this.cols = 2;
        this.rows = 1;
    }

    get getCols() {
        return this.cols;
    }

    get getRows() {
        return this.rows;
    }

    get getTitle() {
        return this.groupTitle;
    }

    setTitle(groupTitle: string) {
        this.groupTitle = groupTitle;
    }

    get getEntries(): Map<string, Entry> {
        return this.entries;
    }

    addEntry(entry: Entry) {
        this.entries.set(entry.uuid, entry);
    }

    removeEntry(entry: Entry) {
        this.entries.delete(entry.uuid);
    }

    removeEntryByUuid(uuid: string) {
        this.entries.delete(uuid);
    }
}
