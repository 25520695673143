import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {DashboardService} from "./dashboard.service";
import {EntryGroup} from "../model/entry-group";
import {Entry} from "../model/entry";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    entryGroups: EntryGroup[];

    constructor(private breakpointObserver: BreakpointObserver,
                public dashboardService: DashboardService) {
        this.entryGroups = [];
    }

    ngOnInit(): void {
        this.refresh();
    }

    /** Based on the screen size, switch from standard to one column per row */
    cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(({matches}) => {
            if (matches) {
                return [
                    {title: 'Salon', cols: 1, rows: 1},
                    {title: 'Spot', cols: 1, rows: 1},
                    {title: 'Muret', cols: 1, rows: 1},
                    {title: 'Chambre', cols: 1, rows: 1}
                ];
            }

            return [
                {title: 'Salon', cols: 2, rows: 1},
                {title: 'Spot', cols: 1, rows: 1},
                {title: 'Muret', cols: 1, rows: 2},
                {title: 'Chambre', cols: 1, rows: 1}
            ];
        })
    );

    update(entry: Entry) {
        this.dashboardService.update(entry).subscribe(
            status => {
                this.entryGroups = [];
                status.entries.forEach(entry => {
                    const entryGroup: EntryGroup = new EntryGroup();
                    entryGroup.setTitle(entry.identifier);
                    entryGroup.addEntry(entry);
                    this.entryGroups.push(entryGroup);
                })
            }
        );
    }

    refresh() {
        this.dashboardService.getStatus().subscribe(result => {
                this.entryGroups = result;
            },
            error => console.log(error));
    }
}
