import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Status} from "../model/status";
import {Entry} from "../model/entry";
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {EntryGroup} from "../model/entry-group";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private url: string = `${environment.apiUrl}/automate_1/status`;

    constructor(private httpClient: HttpClient) {
    }

    update(entry: Entry): Observable<Status> {
        return this.httpClient.post<Status>(`${environment.apiUrl}/automate_1/update`, `${entry.variable}=${entry.value == '1' ? '0' : '1'}`);
    }

    getStatus(): Observable<EntryGroup[]> {
        const entryGroups: EntryGroup[] = [];
        return this.httpClient.get<Status>(this.url)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(status => status.entries))
        .pipe(map(entries => {
            entries.forEach(entry => {
                const entryGroup: EntryGroup = new EntryGroup();
                entryGroup.addEntry(entry);
                entryGroups.push(entryGroup);
            })

            return entryGroups;
        }));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }
}
